import App from "../vue/App.vue";
import Vuex from 'vuex';

// App main
const main = async() => {
  // Create Vuex Store
  const store = new Vuex.Store({
    state: {
      visibleModals: [],
    },
    mutations: {
      showModal(state, tag) {
        if(tag && tag.length > 0) {
          // Clear visibleModals - there can be only one
          state.visibleModals = [];
          // Add desired modal's tag
          state.visibleModals.push(tag);
        }
      },
      hideModal(state, tag) {
        if(tag && tag.length > 0) {
          if(state.visibleModals.includes(tag)) {
            const index = state.visibleModals.indexOf(tag);
            if(index > -1) {
              state.visibleModals.splice(index, 1);
            }
          }
        }
      },
      closeModals(state) {
        // Just clear visibleModals
        state.visibleModals = [];
      }
    }
  });

  // Async load the Vue 3 APIs we need rom the Vue ESM
  const { createApp } = await import(/* webpackChunkName: "vue" */ 'vue');

  // Create our vue instance
  const app = createApp(App);

  // Tie the store to the app
  app.use(store);

  // Mount the app
  const root = app.mount('#app');

  return root;
}

// Execute async function
main().then((root) => {
  window.onload = () => {
    setTimeout(function () {
      var app = document.getElementById('app');
      if (app) { app.style.opacity='1.0'; }
    }, 100);
  }
});
