<script>
	// Async load the Vue 3 API
	import { defineAsyncComponent, defineComponent } from "vue";

	export default defineComponent({
		components: {
			helloWorld: defineAsyncComponent(() =>
				import(/* webpackChunkName: "helloWorld" */ "../vue/HelloWorld.vue")
			),
			ModalWrap: defineAsyncComponent(() =>
				import(/* webpackChunkName: "ModalWrap" */ "../vue/ModalWrap.vue")
			),
			ModalNav: defineAsyncComponent(() =>
				import(/* webpackChunkName: "ModalNav" */ "../vue/ModalNav.vue")
			),
			ModalMobileNav: defineAsyncComponent(() =>
				import(
					/* webpackChunkName: "ModalMobileNav" */ "../vue/ModalMobileNav.vue"
				)
			),
			ModalMap: defineAsyncComponent(() =>
				import(/* webpackChunkName: "ModalMap" */ "../vue/ModalMap.vue")
			),
			ModalIconNav: defineAsyncComponent(() =>
				import(/* webpackChunkName: "ModalIconNav" */ "../vue/ModalIconNav.vue")
			),
			AccordionItem: defineAsyncComponent(() =>
				import(
					/* webpackChunkName: "AccordionItem" */ "../vue/AccordionItem.vue"
				)
			),
			SignPost: defineAsyncComponent(() =>
				import(/* webpackChunkName: "SignPost" */ "../vue/SignPost.vue")
			),
			SchoolPanel: defineAsyncComponent(() =>
				import(/* webpackChunkName: "SchoolPanel" */ "../vue/SchoolPanel.vue")
			),
		},
	});
</script>
